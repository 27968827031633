import React from 'react';
import { graphql } from 'gatsby';

import Courses from '../../components/courses/enterprise/Courses';

export default function CourseIndex({ data: { allCoursesYaml }, pageContext }) {
  const { currentPage, numPages } = pageContext;

  return (
    <Courses
      courses={allCoursesYaml.edges}
      currentPage={currentPage}
      totalPages={numPages}
    ></Courses>
  );
}

export const pageQuery = graphql`
  query EnterpriseCoursesQuery($skip: Int!, $limit: Int!) {
    allCoursesYaml(
      filter: { status: { eq: "public" }, type: { eq: "enterprise" } }
      sort: { fields: createdAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          status
          slug
          title
          excerpt
          price
          promotion
          meta {
            videos
            units
            hours
            level
          }
          coverImage: image {
            childImageSharp {
              fixed(width: 730, height: 415, quality: 50, webpQuality: 50) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          mobileImage: image {
            childImageSharp {
              fluid(maxWidth: 450, quality: 50, webpQuality: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          desktopImage: image {
            childImageSharp {
              fixed(width: 150, height: 85, quality: 50, webpQuality: 50) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
